.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading{
  max-height: 100vh;
  text-align: center;

  svg{
    margin-top: 10vh;
    height: 80vh !important;
  }
}

/*Tripo color */
$mostaza-triplo: #ff8600;
$azul-oscuro-triplo: #003f5f;
$azul-medio-triplo: #0071ad;
$azul-semiclaro-triplo: #7fc9ea;
$azul-claro-triplo: #e6f9ff;
$naranja-oscuro-triplo: #c14717;
$naranja-claro-triplo: #ff6522;
$crema-bold-triplo: #ffedd2;
$crema-suave-triplo: #fff4e6;
$text-menu-triplo: #FFE8DA;
$oscuro-triplo: #001821;
$label-form: #5e6162;

.carousel-status{
  display: none;
}
.p20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.displayMobil{
  display: none !important;
  @media screen and (max-width: 576px){
    display: inherit !important;
  }

}
.displayDesktop{
  display: inherit !important;
  @media screen and (max-width: 576px){
    display: none !important;
  }
}
.mpointer{
  cursor: pointer;
}
.mt-4{
  margin-top: 20px;
}
.mb-4{
  margin-bottom: 20px;
}
.prelative{
  position: relative;
}
a{
  text-decoration:none;
}
.modal-header{
  border-bottom: solid 2px gray;
  padding-bottom: 0;
  .modal-title{
    width: 100%;
    text-align: center;
    color: gray;
    font-size: 30px;
    font-family: 'AT Surt DemiBold';
    padding-bottom: 10px;
  }
}
.modal-content{
  background-color: rgb(255, 244, 230);
  color: #5e6162;
}
// .modal{
//   height: 80%;
//   top: 10%;
//   .close{
//     font-size: 40px;
//     line-height: 25px;
//     color: $oscuro-triplo;
//     opacity: 1;
//   }
//   .modal-body{
//     .row{
//       margin: 0px 6px;
//     }
//   }
// }
.p0{
  padding: 0;
}