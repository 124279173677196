/*Tripo color */
$mostaza-triplo: #ff8600;
$azul-oscuro-triplo: #003f5f;
$azul-medio-triplo: #0071ad;
$azul-semiclaro-triplo: #7fc9ea;
$azul-claro-triplo: #e6f9ff;
$naranja-oscuro-triplo: #c14717;
$naranja-claro-triplo: #ff6522;
$crema-bold-triplo: #ffedd2;
$crema-suave-triplo: #fff4e6;
$text-menu-triplo: #FFE8DA;
$oscuro-triplo: #001821;
$label-form: #5e6162;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.m-r-20 {
  margin-right: 20px;
}

@font-face {
  font-family: "AT Surt Bold";
  src: url("./assets/fonts/AT Surt Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: "AT Surt Black";
  src: url("./assets/fonts/AT Surt Black.otf");
  font-display: swap;
}

@font-face {
  font-family: "AT Surt DemiBold";
  src: url("./assets/fonts/AT Surt DemiBold.otf");
  font-display: swap;
}

@font-face {
  font-family: "AT Surt Light";
  src: url("./assets/fonts/AT Surt Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "AT Surt Medium";
  src: url("./assets/fonts/AT Surt Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "AT Surt Regular";
  src: url("./assets/fonts/AT Surt Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "AT Surt SemiBold";
  src: url("./assets/fonts/AT Surt SemiBold.otf");
  font-display: swap;
}

@font-face {
  font-family: "MangoGrotesque-Bold";
  src: url("./assets/fonts/MangoGrotesque-Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: "MangoGrotesque-SemiBold";
  src: url("./assets/fonts/MangoGrotesque-SemiBold.otf");
  font-display: swap;
}

@font-face {
  font-family: "MangoGrotesque-Black";
  src: url("./assets/fonts/MangoGrotesque-Black.otf");
  font-display: swap;
}

@font-face {
  font-family: "MangoGrotesque-Regular";
  src: url("./assets/fonts/MangoGrotesque-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "Assistant";
  src: url("./assets/fonts/Assistant-VariableFont_wght.ttf");
  font-display: swap;
}

.loadLottie {
  width: 300px; 
  height: 300px; 
  margin: 0 auto;
}
#scroll-to-top{
  padding: 10px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 10;
  cursor: pointer;
  background: #ff8600;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  img{
    transform: rotate(180deg);
  }
}
.display-scroll{
  visibility: visible;
  opacity: 1;
}
.display-scroll-no{
  visibility: hidden;
  opacity: 0;
}
.copy-icon{
  span{
    margin-left: 15px !important;
    transition: all 0.3s ease-in-out;
  }
  svg{
    vertical-align: middle;
    width: auto;
    height: 35px;
    fill: #ff8835;
    margin-left: 28px;
    cursor: pointer;
  }
}

.display-scroll-1{
  visibility: visible;
  opacity: 1;
  margin-left: 5px;
  @media screen and (max-width: 768px){
    span{
      position: absolute;
      right: 21px;
      bottom: -22px;
    }
  }
}
.display-scroll-no-1{
  visibility: hidden;
  opacity: 0;
  
}
.logou{
  width: 200px;
  margin-top:40px;
}

.pu{
  font-size: 30px;
  font-family: 'AT SURT DEMIBOLD';
}
.btn-1{
  background: #ff8600;
  border: none;
  font-size: 33px;
  padding: 5px 50px;
}

.video-background{
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  background-color: black;
}
.video__player {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  transition: opacity .5s ease .5s;

  video{
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
  }
}

.d-desktop{
  display: block;
  @media screen and (max-width: 576px){
      display: none;
  }
}
.d-mobile{
  display: none;
  @media screen and (max-width: 576px){
      display: block;
  }
}
.header_home{
  position: absolute;
  bottom: 20px;
  left: 80px;
  text-align: left;
  max-width: 700px;
  color: $text-menu-triplo;

  @media screen and (max-width: 576px){
      max-width: -webkit-fill-available;
      left: 0;
      right: 0;
      bottom: 0;
      top: 170px;
  }

  p.btn{
      background-color: transparent;
      border-color: $text-menu-triplo;
      color: $text-menu-triplo;
      border-radius: 0;
      border-width: 2px;
      margin-right: 20px;
      font-size: 45px;
      font-family: "MangoGrotesque-Regular";
      line-height: 1;
      padding: 15px 20px;
      text-align: center;
      cursor: pointer;

      &:hover{
          background-color: $mostaza-triplo !important;
          color: $oscuro-triplo !important;
          border-color: $mostaza-triplo !important;
      }
      &:active{
          background-color: $mostaza-triplo !important; 
          color: $oscuro-triplo !important;
          border-color: $mostaza-triplo !important;
      }

      @media screen and (max-width: 576px){
          margin-right: auto;
          width: 250px;
          margin-bottom: 20px;
          margin-left: auto;
          display: block;
      }
  }

  .h11{
      font-family: "MangoGrotesque-Regular";
      font-size: 90px;
      line-height: 1;
      color: $text-menu-triplo;
      text-align: left;
      margin: 0;
      @media screen and (max-width: 576px){
          text-align: right;
          font-size: 70px;
          line-height: 0.8;
      }
  }
  p{
      text-align: left;
      font-family: 'AT Surt Medium';
      font-size: 25px;
      color: $text-menu-triplo;
      margin: 0;
      @media screen and (max-width: 576px){
          text-align: right;
          font-size: 20px;
      }
  }
  .margin_text{
      margin-bottom: 50px;
      margin-top: 100px;
      @media screen and (max-width: 576px){
          text-align: center;
          margin-left: auto;
          margin-right: auto;
      }
  }
  .margin_top{
      @media screen and (max-width: 576px){
          margin-top:30px;
      }
  }
}
#video-desktop {
  display: none;
}

#video-mobile {
  display: none;
}

@media only screen and (min-width: 768px) {
  #video-desktop {
      display: block;
  }
}

@media only screen and (max-width: 767px) {
  #video-mobile {
      display: block;
  }
}